
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { SimpleButton } from './components.js';

const SimpleHeaderBar = ({ actionButton, logoLink }) => {
  return (
    <Box sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        px: 5,
        py: 2,
        borderBottom: '1px solid #bbb',
      }}>
      <Stack direction="row"
          spacing={4}
          sx={{
            px: 4,
            flexGrow: 1,
            maxWidth: 1440,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
        <SimpleButton href={logoLink || "#/"}>
          <Typography variant="h5">VoiceTopics</Typography>
        </SimpleButton>
        {actionButton}
      </Stack>
    </Box>
  );
};

export default SimpleHeaderBar;
